export const CONTACT_FORM = {
  category: 'Contact form',
  successSubmitAction: 'Success submit',
  errorSubmitAction: 'Error on submit form',
}


export const CALLBACK_CONTACT_FORM = {
  category: 'Contact back form',
  successSubmitAction: 'Success submit',
  errorSubmitAction: 'Error on submit form',
}
