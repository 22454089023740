import React from "react"
import ReactGA from 'react-ga'
import styled from "styled-components"
import PropTypes from "prop-types"
import { FaPhoneAlt } from "react-icons/fa"
import theme from "../theme"
import Button from "../components/Button"
import { CONTACT_FORM } from '../config/analytics-events'
import { encodeForm } from './utils'

const ContactSection = () => {
  const [notification, setNotification] = React.useState(null)
  const [sending, toggleSending] = React.useState(false)
  const [formValues, setFormValues] = React.useState({})

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  const resetForm = () => {
    // not sure why, but I need both lines to reset the form
    setFormValues({})
    document.getElementById("contact-form").reset()
  }

  const onSubmit = (e) => {
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
      return false
    }

    const {
      inputName, inputEmail, inputMessage, inputPhone, inputService,
    } = formValues
    e.preventDefault()
    toggleSending(true)
    fetch('/php/contact-form.php', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: encodeForm({
        emailTitle: 'Contact Message - ANL Website',
        inputName,
        inputPhone,
        inputEmail,
        inputService,
        inputMessage,
      }),
    })
      .then((res) => {
        toggleSending(false)
        if (res.ok) {
          ReactGA.event({
            category: CONTACT_FORM.category,
            action: CONTACT_FORM.successSubmitAction,
          })
          return res.json()
        }
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        return { error: 'Error sending form, please try again later.' }
      })
      .then((data) => {
        if (data.error || !data.success) {
          setNotification({ success: false, message: data.messageEnglish })
        } else {
          resetForm()
          setNotification({ success: true, message: data.messageEnglish })
        }
      })
      .catch((error) => {
        console.error(error)
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        toggleSending(false)
      })
  }

  const {
    inputName, inputEmail, inputMessage, inputPhone, inputService,
  } = formValues

  return (
    <>
      <StyledSection className="container">
        <StyledLeftSection>
          <div className="black-stripe" />
          <h1 className="primary-underline">Contact Us</h1>
          <p>
            35a Veronica Street <br />
            New Lynn, Auckland <br />
            Monday to Friday: 8:00am - 4:30pm
          </p>
          <HolidaysHours>
            <h4>Holiday Hours</h4>
            <p>Friday 22nd Dec: 8:00 am - 12:30 pm</p>
            <p>23rd - 26th Dec: <b>Closed</b></p>
            <p>27th - 29th Dec: Open 8:00 am - 4:30 pm</p>
            <p>30th Dec - 3rd Jan: <b>Closed</b></p>
            <p>Normal hours resume on 4th January 2024, 8:00 am - 4:30 pm</p>
          </HolidaysHours>
          <a href="tel:098277563" className="lg-link-text">
            <FaPhoneAlt style={{ fontSize: "50px", marginRight: "10px" }} />
            (09) 8277563
          </a>
          <div>
            <Button
              target="blank"
              to="https://maps.google.com/maps?ll=-36.904801,174.685604&z=16&t=m&hl=en&gl=NZ&mapclient=embed&daddr=Automotive%20New%20Lynn%2035a%20Veronica%20Street%20New%20Lynn%20Auckland%200600@-36.9048014,174.6856045"
              type="greenToOutline"
            >
              Get Directions
            </Button>
          </div>
        </StyledLeftSection>

        <StyledRightSection>
          <div>
            <h1>Send a Message</h1>
            <form id="contact-form" onSubmit={onSubmit}>
              <input value={inputName} onChange={handleChange} type="text" name="inputName" placeholder="Name" required />
              <section>
                <input value={inputEmail} onChange={handleChange} type="email" name="inputEmail" placeholder="Email Address" required />
                <input value={inputPhone} onChange={handleChange} type="text" name="inputPhone" placeholder="Phone Number" />
              </section>
              <select onChange={handleChange} name="inputService" value={inputService}>
                <option value="">Service Required</option>
                <option value="Warrant of Fitness">Warrant of Fitness</option>
                <option value="Auto Electrical">Auto Electrical</option>
                <option value="Air Conditioning">Air Conditioning</option>
                <option value="Brakes or Suspension">
                  Brakes or Suspension
                </option>
                <option value="Engine Tuning">Engine Tuning</option>
                <option value="Other">Other</option>
              </select>

              <textarea
                name="inputMessage"
                cols="30"
                rows="10"
                placeholder="Message"
                id="Message"
                value={inputMessage}
                onChange={handleChange}
              ></textarea>
            <SubmitButton disabled={sending} type="submit">{sending ? 'Sending ...' : 'Send'}</SubmitButton>
            </form>
            <div>
            {notification && notification.success
              && <ContactMessage>{notification.message}</ContactMessage>
            }
            {notification && !notification.success
              && <ContactMessage>{notification.message}</ContactMessage>
            }
          </div>
          </div>
        </StyledRightSection>
      </StyledSection>
    </>
  )
}

const StyledSection = styled.section`
  display: grid;
  grid-template-columns: 54% 46%;

  @media (max-width: 1024px) {
    display: block;
    max-width: 85%;
  }
`

const ContactMessage = styled.p`
  font-size: 18px;
  text-align: center;
  color: white;
`

const StyledLeftSection = styled.section`
  position: relative;
  padding: 40px;
  h1 {
    font-family: "Khand";
    font-size: 100px;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;
    color: #333;
    margin-bottom: 0.3em;
    margin-top: 0.5em;
  }

  p {
    font-size: 24px;
    max-width: 600px;
    line-height: 2em;
    font-weight: 600;
    color: #666;
  }

  .lg-link-text {
    display: block;
    color: ${theme.primaryColor};
    text-decoration: none;
    font-size: 35px;
    font-family: "Khand", sans-serif;
    font-weight: bold;
    font-style: italic;
    margin: 50px 0;
  }

  .black-stripe {
    height: 80px;
    width: 100vw;
    transform: skew(-22deg);
    position: absolute;
    top: 0;
    right: 20%;
    background: black;
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 85%;
  }

  @media (min-width: 1120px) {
    padding: 80px 60px;
    .lg-link-text {
      font-size: 60px;
    }
  }
  @media (min-width: 984px) {
    h1 {
      font-size: 8vw;
    }
  }
`
const StyledRightSection = styled.section`
  width: calc(40% + (100vw - 100%) / 2);
  box-sizing: border-box;
  color: white;
  div {
    background: ${theme.primaryColor};
    padding: 60px 40px;
  }
  h1 {
    text-transform: uppercase;
    font-size: 30px;
    font-family: "Khand";
  }
  option {
    color: black;
  }

  input,
  textarea,
  select {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    display: block;
    color: white;
    padding: 16px;
    background-color: transparent;
    &::placeholder {
      font-size: 0.875rem;
      color: white;
      font-weight: 600;
    }
  }

  select {
    font-size: 0.875rem;
    color: white;
    font-weight: 600;
    padding-left: 13px;
  }
  section {
    input {
      display: initial;
      width: 50%;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin: auto;
    max-width: 85%;
    section {
      input {
        display: block;
        width: 100%;
      }
    }
  }
  @media (min-width: 1024px) {
    h1 {
      font-size: 60px;
    }
  }
`

const SubmitButton = styled.button`
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: 3px;
  display: block;
  margin-top: 20px;
  margin-left: auto;

  &::after {
    content: "E";
    font-family: "elegant-icons";
    position: relative;
    top: 1px;
  }
`
const HolidaysHours = styled.div`
  background: #fafafa;
  padding: 5px 15px;
  p {
    font-size: 14px !important;
    line-height: 1;
  }
`

ContactSection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ContactSection
