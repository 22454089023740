import React from "react"
import styled from "styled-components"

const MapEmbed = () => {
  return (
    <StyledDiv>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.395741427814!2d174.6834158155388!3d-36.90480137992711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d414a516beca5%3A0x1be5e30bdef5c125!2sAutomotive%20New%20Lynn!5e0!3m2!1sen!2snz!4v1574291734101!5m2!1sen!2snz"
        width="100%"
        height="450"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  width: 80%;
  margin: auto;
  max-width: 1080px;
  margin-top: 70px;
  margin-bottom: 70px;
  iframe {
    border: 0;
  }
`

export default MapEmbed
